import type { AnyAction, CombinedState, Reducer } from '@reduxjs/toolkit';

const HYDRATE = '__NEXT_REDUX_WRAPPER_HYDRATE__';

export const hydrateReducer =
  <S extends Record<string, unknown>, IS extends S>(
    reducer: Reducer<CombinedState<S>>,
    initialState: IS
  ): Reducer<S> =>
  // eslint-disable-next-line default-param-last
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case HYDRATE:
        return { ...state, ...action.payload };
      default:
        return reducer(state, action);
    }
  };
