import { Option } from '@doda/common/src/interfaces/app/adobeAnalytics';
import { Dispatch } from '@reduxjs/toolkit';
import { setStackedAdobeAnalyticsClickEvent } from '../redux/features/analytics';

/**
 * 計測オブジェクトの正規化
 */
export const normalize = () => {
  window.s = window.s || {};
  window.sc = window.sc || {};

  // 不要eventsの削除
  if (window.s.events) {
    delete window.s.events;
  }

  if (window.sc.events) {
    delete window.sc.events;
  }
};

/**
 * Adobe Analytics tl関数
 * @see https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/tl-method.html?lang=ja
 * @param linkName リンク名
 * @param option オプション
 */
export const tl = (linkName: string, option: Option) => {
  let hasScriptNotLoaded = false;
  if (typeof window.s === 'object' && !Object.keys(window.s).length) {
    // window.sが取得できなかった場合実行されているログ
    window.DD_LOGS?.logger.info(`${linkName}: s fail`);
    hasScriptNotLoaded = true;
  }

  const s = window.s || {};

  if (typeof s.tl === 'function') {
    // SCオブジェクト上書き※s_doPluginsメソッドに対する暫定対応 see `https://assets.adobedtm.com/31daf803aaa6/570c99f12da1/2d7c2c962fa1/EX5d01c709daad4d5bbd23254a99049e7e-libraryCode_source.js`.
    const scTmp = { ...window.sc };
    window.sc = { ...window.sc, ...option };

    // tlメソッド実行
    s.tl(this, 'o', linkName, option);

    // SCオブジェクトをtlメソッド実行前に戻す
    window.sc = { ...scTmp };

    // オブジェクト正規化
    normalize();
    if (hasScriptNotLoaded) {
      // window.s取得失敗後に再取得できた場合のログ
      window.DD_LOGS?.logger.info(`${linkName}: s success`);
    }
  }
};

/**
 * Adobe Analytics tl関数(第一引数がtrue: ブラウザーがページから移動するまで待機しない)
 * @see https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/tl-method.html?lang=ja
 * @param linkName リンク名
 * @param option オプション
 */
export const tlNotWait = (linkName: string, option: Option) => {
  const s = window.s || {};

  if (typeof s.tl === 'function') {
    // SCオブジェクト上書き※s_doPluginsメソッドに対する暫定対応 see `https://assets.adobedtm.com/31daf803aaa6/570c99f12da1/2d7c2c962fa1/EX5d01c709daad4d5bbd23254a99049e7e-libraryCode_source.js`.
    const scTmp = { ...window.sc };
    window.sc = { ...window.sc, ...option };

    // tlメソッド実行
    s.tl(true, 'o', linkName, option);

    // SCオブジェクトをtlメソッド実行前に戻す
    window.sc = { ...scTmp };

    // オブジェクト正規化
    normalize();
  }
};

/**
 * Adobe Analytics t関数
 * normalizeによりeventsが初期化されてしまう可能性があるため、基本的には、tlより先に発火をさせる
 * @param delayNomalizeTime s.tの内部処理より先にnormalizeが実行してしまう場合は、${delayNomalizeTime}ミリ秒遅らせる
 * @see https://experienceleague.adobe.com/docs/analytics/implementation/vars/functions/t-method.html?lang=ja
 */
export const t = (delayNomalizeTime = 0) => {
  let hasScriptNotLoaded = false;

  let count = 0;
  const timer = setInterval(() => {
    count += 1;
    if (window.s && typeof window.s?.t === 'function') {
      clearTimeout(timer);
      window.s.t();
      // オブジェクト正規化
      setTimeout(() => {
        normalize();
      }, delayNomalizeTime);

      if (hasScriptNotLoaded) {
        // window.s取得失敗後に再取得できた場合のログ
        window.DD_LOGS?.logger.info(`${window.sc?.pageName}: s success`);
      }
    }
    if (count >= 10) {
      if (typeof window.s === 'object' && !Object.keys(window.s).length) {
        // window.sが取得できなかった場合実行されているログ
        /* eslint-disable-next-line no-console */
        window.DD_LOGS?.logger.info(`${window.sc?.pageName}: s fail`);
        hasScriptNotLoaded = true;
      }
      clearTimeout(timer);
    }
  }, 300);
};

/**
 * データオブジェクト
 * @param option
 */
export const sc = (option: Option) => {
  window.sc = window.sc || {};

  window.sc = {
    ...window.sc,
    ...option,
  };
};

/**
 * 遷移前のページ名を取得する
 * @returns {string} 遷移前のページ名
 */
export const getPreviousPageName = (): string => {
  const s = window.s || {};

  /**
   * 遷移前のページ名がs_doPlugins関数内で設定されている
   * ページ名の設定には[getPreviousValue]プラグインを使用し
   * Cookieを経由することで画面遷移をしてもページ名を連携している
   */
  if (s.prop69) {
    return s.prop69;
  }

  return '';
};

/**
 * ページ名を取得する
 * @returns {string} ページ名
 */
export const getPageName = (): string => {
  const s = window.s || {};

  if (s.pageName) {
    return s.pageName;
  }

  return '';
};

/**
 * Channelを取得する
 * @returns {string} ページ名
 */
export const getChannel = (): string => {
  const s = window.s || {};

  if (s.channel) {
    return s.channel;
  }

  return '';
};

/**
 * Adobe Analytics tl関数
 * スクリプトの読み込み前にイベント到達した場合、読み込み後実行できるよう保存
 * @param isAdobeScript
 * @param dispatch
 * @param linkName リンク名
 * @param option オプション
 */
export const tlReadyExecute = (
  isAdobeScript: boolean,
  dispatch: Dispatch,
  linkName: string,
  option: Option
) => {
  if (isAdobeScript) {
    tl(linkName, option);
  } else {
    dispatch(setStackedAdobeAnalyticsClickEvent({ linkName, option }));
    window.DD_LOGS?.logger.info(`${linkName}: tl unfired`);
  }
};
