// objectにプロパティが存在するかどうか
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function existProperty(object: Record<string, any>) {
  if (isObject(object)) {
    return Object.keys(object).length > 0;
  }
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const existPropertyNumbers = (props: Record<string, any>) => {
  let num = 0;
  Object.values(props).forEach((v) => {
    if (v) num += 1;
  });
  return num;
};

export function isObject(value) {
  return value !== null && typeof value === 'object';
}

/**
 * objectからnullableなvalueをもつプロパティ削除する
 * @param obj
 * @returns
 */
export const removeUndefinedFromObject = <T>(obj: { [key: string]: T }) =>
  Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== undefined && v !== null)
  );
