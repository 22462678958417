export const CMSMENBERINFO_KEYS = {
  all: 'SPTOP,bookmarkCnt,desiredCondText,jobHistoryCnt,counselingButton,counselingReservedCheckButton,referredJobCnt,saiyoprojectCnt,offerCnt,interviewOfferCnt,interviewCnt,messageCnt,progressTopCnt,ngServiceIn,partnerAgentScoutCnt,entryCnt',
  bookmark: 'bookmarkCnt',
  counselingButton: 'counselingButton',
  desiredCond: 'desiredCondText',
  tabs: 'bookmarkCnt,desiredCondText,jobHistoryCnt,entryCnt',
  loginStatus: 'loginStatus,entryCnt',
  pcHeader: 'bookmarkCnt,jobHistoryCnt',
} as const;

export type CmsMemberInfoKey = keyof typeof CMSMENBERINFO_KEYS;
