import { configureStore, Reducer, Store } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { isDevelopment } from '@doda/common/src/utils/isDevelopment';
import { rtkQueryMiddleWares } from '../rtkQuery';
import { RootState } from '../store';

export const makeStore = (reducer: Reducer) => () => {
  return configureStore({
    reducer,
    devTools:
      /* 本番環境ではdev toolが使用できないように設定 */
      isDevelopment,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(rtkQueryMiddleWares),
  });
};

export const createReduxWrapper = (reducer: Reducer) => {
  return createWrapper<Store<RootState>>(makeStore(reducer), {
    debug: isDevelopment,
  });
};
