import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CMSMENBERINFO_KEYS,
  CmsMemberInfoKey,
} from '../constants/cmsMemberInfoApiKey';
import { CmsMemberInfo } from '../interfaces/api/cmsMemberInfo';
import { appAxios } from '../libs/axios/appAxios';

/**
 * MemberInfo取得
 */
export const getCmsMemberInfo = createAsyncThunk<
  CmsMemberInfo,
  CmsMemberInfoKey | undefined,
  { rejectValue: Record<string, never> }
  // eslint-disable-next-line default-param-last
>('app/setCmsMemberInfo', async (key = 'tabs', thunkAPI) => {
  try {
    const response = await appAxios({
      url: '/dcfront/cms/cmsMemberInfo/',
      params: {
        keys: CMSMENBERINFO_KEYS[key],
      },
    });
    return await response.data;
  } catch {
    return thunkAPI.rejectWithValue({});
  }
});
