import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { CmsMemberInfo } from '../../../interfaces/api/cmsMemberInfo';
import { RootState } from '../../store';
import { getCmsMemberInfo } from '../../../services/getCmsMemberInfo';

/**
 * 初期型
 */
export interface MemberInfoState {
  memberInfo: CmsMemberInfo | null;
  existsMemberInfoData: boolean;
}

// 初期状態
export const memberInfoInitialState: MemberInfoState = {
  memberInfo: null,
  existsMemberInfoData: false,
};

/**
 * State型
 */
export interface MemberInfoSliceState {
  memberInfo: ReturnType<typeof memberInfoReducer>;
}

export const memberInfoSlice = createSlice({
  name: 'memberInfo',
  initialState: memberInfoInitialState,
  reducers: {
    setMemberInfo: (state, action: PayloadAction<CmsMemberInfo>) => {
      state.memberInfo = { ...state.memberInfo, ...action.payload };
    },
    setBookmark: (
      state,
      action: PayloadAction<CmsMemberInfo['bookmarkCnt']>
    ) => {
      if (!state.memberInfo) return;

      state.memberInfo.bookmarkCnt = action.payload;
    },
    initJobHistoryCount: (state) => {
      if (!state.memberInfo) return;

      state.memberInfo.jobHistoryCnt = 0;
    },
    setMemberInfoForClient: (state, action: PayloadAction<CmsMemberInfo>) => {
      const {
        payload: { bookmarkCnt, jobHistoryCnt, desiredCondText },
      } = action;
      if (!state.memberInfo) return;
      state.memberInfo.bookmarkCnt = bookmarkCnt;
      state.memberInfo.jobHistoryCnt = jobHistoryCnt;
      state.memberInfo.desiredCondText = desiredCondText;
    },
    setUserName: (
      state,
      action: PayloadAction<{
        firstName: string | null;
        lastName: string | null;
      }>
    ) => {
      const { firstName, lastName } = action.payload;
      if (!state.memberInfo) return;
      state.memberInfo.firstName = firstName;
      state.memberInfo.lastName = lastName;
    },
    setExistsMemberInfoData: (state, action: PayloadAction<boolean>) => {
      state.existsMemberInfoData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCmsMemberInfo.fulfilled,
      (state, action: PayloadAction<CmsMemberInfo>) => {
        const {
          payload: { bookmarkCnt, jobHistoryCnt },
        } = action;
        if (!state.memberInfo) return;
        state.memberInfo.bookmarkCnt = bookmarkCnt;
        state.memberInfo.jobHistoryCnt = jobHistoryCnt;
      }
    );
  },
});

// Create Action
export const {
  setMemberInfo,
  setBookmark,
  setMemberInfoForClient,
  setUserName,
  setExistsMemberInfoData,
  initJobHistoryCount,
} = memberInfoSlice.actions;

// reducer
export const memberInfoReducer = memberInfoSlice.reducer;

// selector
/**
 * loginStatusSelector
 * @param state
 * @returns ログイン状態フラグ
 */
export const loginStatusSelector = (state): boolean =>
  state.memberInfo.memberInfo?.loginStatus ?? false;

/**
 * memberInfoSelector
 * @param state
 * @returns ユーザー情報
 */
export const memberInfoSelector = (state): CmsMemberInfo | null =>
  state.memberInfo.memberInfo ?? null;

/**
 * desiredCondSelector
 * @param state
 * @returns 希望条件
 */
export const desiredCondSelector = (state: RootState) =>
  state.memberInfo.memberInfo?.desiredCondText || null;

/**
 * bookmarkCntSelector
 * @param state
 * @returns 気になる件数
 */
export const bookmarkCntSelector = (state: RootState): number | undefined =>
  state.memberInfo.memberInfo?.bookmarkCnt;

/**
 * wsfidSelector
 * @param state
 * @returns 個人顧客ID
 */
export const wsfidSelector = (state) => state.memberInfo.memberInfo?.wsfid;

export const userNameSelector = createSelector(memberInfoSelector, (state) => {
  return state?.loginStatus
    ? // eslint-disable-next-line no-irregular-whitespace
      `${state.lastName ?? ''}　${state.firstName ?? ''}さん`
    : null;
});

export const bookmarkDisplayCntSelector = createSelector(
  bookmarkCntSelector,
  (state) => {
    if (state === undefined) return 0;
    return state < 100 ? state : '99+';
  }
);

/**
 * existsMemberInfoDataSelector
 * @param state
 * @returns existsMemberInfoData
 */
export const existsMemberInfoDataSelector = (state: RootState) =>
  state.memberInfo.existsMemberInfoData;
