import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AdobeOtherClickParams } from '../../../interfaces/app/analytics';
import { RootState } from '../../store';

/**
 * 初期型
 */
export interface AnalyticsState {
  hasAdobeAnalyticsScripts: boolean;
  hasGoogleAnalyticsScripts: boolean;
  stackedAdobeAnalyticsClickEvent: Array<AdobeOtherClickParams>;
}

/**
 * State型
 */
export interface AnalyticsSliceState {
  analytics: ReturnType<typeof analyticsReducer>;
}

// 初期状態
export const analyticsInitialState: AnalyticsState = {
  hasAdobeAnalyticsScripts: false,
  hasGoogleAnalyticsScripts: false,
  stackedAdobeAnalyticsClickEvent: [],
};

// slice
export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: analyticsInitialState,
  reducers: {
    setAdobeAnalyticsScripts: (state, action) => {
      state.hasAdobeAnalyticsScripts = action.payload;
    },
    setGoogleAnalyticsScripts: (state, action) => {
      state.hasGoogleAnalyticsScripts = action.payload;
    },
    setStackedAdobeAnalyticsClickEvent: (
      state,
      action: PayloadAction<AdobeOtherClickParams>
    ) => {
      state.stackedAdobeAnalyticsClickEvent.push(action.payload);
    },
  },
});

// Create Action
export const {
  setAdobeAnalyticsScripts,
  setGoogleAnalyticsScripts,
  setStackedAdobeAnalyticsClickEvent,
} = analyticsSlice.actions;

// reducer
export const analyticsReducer = analyticsSlice.reducer;

// selector
/**
 * adobeAnalyticsScriptsSelector
 * @param state
 * @returns hasAdobeAnalyticsScripts
 */
export const adobeAnalyticsScriptsSelector = (state): boolean =>
  state.analytics.hasAdobeAnalyticsScripts;

/**
 * googleAnalyticsScriptsSelector
 * @param state
 * @returns hasGoogleAnalyticsScripts
 */
export const googleAnalyticsScriptsSelector = (state): boolean =>
  state.analytics.hasGoogleAnalyticsScripts;

/**
 * stackedAdobeAnalyticsClickEventSelector
 * @param state
 * @returns stackedAdobeAnalyticsClickEvent
 */
export const stackedAdobeAnalyticsClickEventSelector = (
  state: RootState
): Array<AdobeOtherClickParams> =>
  state.analytics.stackedAdobeAnalyticsClickEvent;
