import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

/**
 *  Redux カスタムHooks
 *  useAppDispatch Hook
 * @remarks プレーンなuseDispatchの代わりに使用する
 * @example
 * const dispatch = useAppDispatch();
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * useAppSelector Hook
 * @remarks プレーンなuseSelectorの代わりに使用する
 * @example
 * const value = useAppSelector((state) => state.value);
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
