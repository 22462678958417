import { DODA_SITE_DOMAIN } from '@doda/common/src/constants/urls';
import Head from 'next/head';
import { FC, memo } from 'react';
import { isServer } from '@doda/common/src/utils/isServer';

/**
 * SEO向けタグ・構造化データ情報
 * @param title タイトル
 * @param description description
 * @param keywords キーワード
 * @param type タイプ
 * @param url URL
 * @param image 画像タグ
 * @param siteName サイト名
 * @param canonical カノニカル
 * @param robots robots
 * @param webSiteStructuredData webSite構造化データ
 * @param webPageStructuredData webPage構造化データ
 */
interface Props {
  title?: string;
  description?: string;
  keywords?: string;
  type?: string;
  url?: string;
  image?: string;
  siteName?: string;
  canonical?: string;
  robots?: string;
  webSiteStructuredData?: string;
  webPageStructuredData?: string;
}

/**
 * SEO向けメタタグコンポーネント
 * @param title タイトル
 * @param description description
 * @param keywords キーワード
 * @param type タイプ
 * @param url URL
 * @param image 画像タグ
 * @param siteName サイト名
 * @param canonical カノニカル
 * @param robots robots
 * @param webSiteStructuredData webSite構造化データ
 * @param webPageStructuredData webPage構造化データ
 */
export const Tag: FC<Props> = memo(
  ({
    title,
    description,
    keywords,
    type,
    url,
    image,
    siteName,
    canonical,
    robots,
    webSiteStructuredData,
    webPageStructuredData,
  }) => {
    return (
      <Head>
        {title && <title>{title}</title>}
        {title && description && (
          <meta name="description" content={description} />
        )}
        {title && keywords && <meta name="keywords" content={keywords} />}
        {canonical && (
          <link rel="canonical" href={`${getDomain()}${canonical}`} />
        )}
        {title && <meta property="og:title" content={title} />}
        {type && <meta property="og:type" content={type} />}
        {url && (
          <meta
            property="og:url"
            content={`${url.indexOf('http') > -1 ? '' : getDomain()}${url}`}
          />
        )}
        {image && <meta property="og:image" content={image} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {siteName && <meta property="og:site_name" content={siteName} />}
        {robots && <meta name="robots" content={robots} />}
        {webSiteStructuredData && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: webSiteStructuredData,
            }}
          />
        )}
        {webPageStructuredData && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: webPageStructuredData,
            }}
          />
        )}
      </Head>
    );
  }
);

Tag.displayName = 'Tag';

const getDomain = () => {
  const env = process.env.APP_ENV as string;
  const domain = DODA_SITE_DOMAIN[env];
  if (!domain && !isServer) {
    return `https://${window.location.host}`;
  }
  return domain;
};
