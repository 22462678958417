// #URL静的化対象URL#
export const CONVERT_SUBJECT =
  'DodaFront/View/JobSearchTop.action,DodaFront/View/JobSearchList.action,DodaFront/View/JobSearchDetail.action,DodaFront/View/JobHistoryList.action,DodaFront/View/RelatedJobList.action,DodaFront/View/SeminarList.action,DodaFront/View/SeminarDetail.action,DodaFront/View/NewJobList.action,DodaFront/View/FeaturedJobList.action,engineer/View/ITJobSearchTop.action,engineer/View/HWJobSearchTop.action,engineer/View/ENJobSearchList.action,engineer/View/JobSearchDetail.action,engineer/View/RelatedJobList.action,DodaFront/View/CareerRecommendJobList.action';
// #パラメータ並び替え対象URL（DODA）#
export const SORTSUBJECT_DODA =
  'DodaFront/View/JobSearchList.action,DodaFront/View/FeaturedJobList.action,DodaFront/View/CareerRecommendJobList.action';

// パラメータ優先順位（DODA用）
export const SORTORDER_DODA =
  'jid,fid,qc,ind,ar,pr,ci,wa,rt,st,oc,op,ha,ne,es,k,preBtn,btn,tp,ss,kw,ni,niwc,nearArea,baseArea,page';

// #OGPタグ
export const OGP_TYPE = 'article';
export const OGP_IMAGE = 'https://doda.jp/cmn_web/img/icon_fb.png';
export const OGP_SITE = 'doda';
export const OGP_URL_JOB_SEARCH_DETAIL =
  'https://doda.jp/DodaFront/View/JobSearchDetail/j_jid__%s/';
export const OGP_TITLE_JOB_SEARCH_DETAIL =
  '%s／%sの求人情報 － 転職ならDODA（デューダ）';
export const OGP_DESCRIPTION_JOB_SEARCH_DETAIL =
  '%s／%sの求人・求人情報ならDODA（デューダ）。仕事内容など詳しい採用情報や職場の雰囲気が伝わる情報が満載。';
export const OGP_URL_JOB_SEARCH_TOP =
  'https://doda.jp/DodaFront/View/JobSearchTop/j_from_global_navi__True/';
export const CANONICAL_URL_JOB_SEARCH_TOP =
  '/DodaFront/View/JobSearchTop/j_from_global_navi__True/';
export const OGP_URLJOB_SEARCH_LIST =
  'https://doda.jp/DodaFront/View/JobSearchList.action%s';
export const OGP_URL_JOB_HISTORY_LIST =
  'https://doda.jp/DodaFront/View/JobHistoryList.action';
export const OGP_URL_SEMINAR_LIST =
  'https://doda.jp/DodaFront/View/SeminarList/j_seminar_disp__01/';
export const OGP_FEATURED_JOB_LIST =
  'https://doda.jp/DodaFront/View/FeaturedJobList.action%s';
export const OGP_URL_SEMINAR_DETAIL =
  'https://doda.jp/DodaFront/View/SeminarDetail/j_seminargroup_id__%s/-fm__%s/';
export const OGP_URL_NEW_JOB_LIST =
  'https://doda.jp/DodaFront/View/NewJobList.action';
export const CANONICAL_URL_NEW_JOB_LIST = '/DodaFront/View/NewJobList.action';
export const OGP_URL_ROUTE_LIST =
  'https://doda.jp/DodaFront/View/RouteList/j_/-ar__%s/';
export const OGP_IMAGE_TOP = 'https://doda.jp/guide/img/icon_fb_001_top.gif';
export const OGP_URL_TOP = 'https://doda.jp/';
